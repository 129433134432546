import React, { useState } from 'react';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const PaymentForm = () => {
    const [formData, setFormData] = useState({
        amount: '',
        email: '',
        phone: '',
        fullname: '',
        city: '',
        state: '',
        zip_code: '',
        product_description: '',
        order_id: '',
        userid: '',
        username: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const response = await fetch(`${baseURL}/process_payment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        
        const data = await response.json();
        
        if (data.status === 'success') {
            const paymentForm = document.createElement('form');
            paymentForm.action = 'https://biz.aggrepaypayments.com/v2/paymentrequest';
            paymentForm.method = 'POST';

            for (const key in data.form) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = data.form[key];
                paymentForm.appendChild(input);
            }

            document.body.appendChild(paymentForm);
            paymentForm.submit();
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" name="amount" placeholder="Amount" onChange={handleChange} required />
            <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
            <input type="text" name="phone" placeholder="Phone" onChange={handleChange} required />
            <input type="text" name="fullname" placeholder="Full Name" onChange={handleChange} required />
            <input type="text" name="city" placeholder="City" onChange={handleChange} required />
            <input type="text" name="state" placeholder="State" onChange={handleChange} required />
            <input type="text" name="zip_code" placeholder="Zip Code" onChange={handleChange} required />
            <input type="text" name="product_description" placeholder="Product Description" onChange={handleChange} required />
            <input type="text" name="order_id" placeholder="Order ID" onChange={handleChange} required />
            <input type="text" name="userid" placeholder="User ID" onChange={handleChange} required />
            <input type="text" name="username" placeholder="Username" onChange={handleChange} required />
            <button type="submit">Pay Now</button>
        </form>
    );
};

export default PaymentForm;
