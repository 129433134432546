import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import './Footer.css'; // Ensure you create and import the CSS file

const Footer = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  // State for each collapsible section
  const [isExpanded, setIsExpanded] = useState({
    product: false,
    companyInfo: false,
    personalInfo: false,
    contact: false
  });

  const toggleCollapse = (section) => {
    setIsExpanded(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  return (
    <React.Fragment>
      <div className='footer_container'>
        <div className='container-fluid footer_container'>
          <div className='row footer_row'>
            <div className='for-mobile'>
              <p className='heading' onClick={() => toggleCollapse('product')}>Product &nbsp;<span className='icon-p'><i class="fa fa-plus-circle" aria-hidden="true"></i></span>
              </p>
              <div className={`collapsible ${isExpanded.product ? 'expanded' : ''}`}>
                <ul className='footer_for'>
                  <Link to='/design/1'><li className='footer1'>Acrylic Desktops</li></Link>
                  <Link to='/design/2'><li className='footer1'>Canvas Prints</li></Link>
                  <Link to='/design/3'><li className='footer1'>Wooden Desktops</li></Link>
                  <Link to='/design/10'><li className='footer1'>T-shirts</li></Link>
                  <Link to='/design/5'><li className='footer1'>Notebooks</li></Link>
                </ul>
              </div>
            </div>
            <div className='for-mobile1'>
              <p>&nbsp;</p>
              <ul className='footer_for'>
                <Link to='/design/7'><li className='footer1'>Coasters</li></Link>
                <Link to='/design/8'><li className='footer1'>Tablemats</li></Link>
                <Link to='/design/9'><li className='footer1'>Fridge Whiteboards</li></Link>
                <Link to='/design/24'><li className='footer1'>Framed Prints</li></Link>
              </ul>
            </div>
            <div className='for-mobile'>
              <p className='heading' onClick={() => toggleCollapse('companyInfo')}>Company Info &nbsp;<span className='icon-p'><i class="fa fa-plus-circle" aria-hidden="true"></i></span></p>
              <div className={`collapsible ${isExpanded.companyInfo ? 'expanded' : ''}`}>
                <ul className='footer_for'>
                  <Link to='/'><li className='footer1'>Home</li></Link>
                  <Link to='/about-us'><li className='footer1'>About Us</li></Link>
                  <Link to='/privacy-policy'><li className='footer1'>Privacy Policy</li></Link>
                  <Link to='/terms-condition'><li className='footer1'>Terms & Conditions</li></Link>
                  <Link to='/contact-us'><li className='footer1'>Contact</li></Link>
                </ul>
              </div>
            </div>
            <div className='for-mobile'>
              <p className='heading' onClick={() => toggleCollapse('personalInfo')}>Personal Info &nbsp;<span className='icon-p'><i class="fa fa-plus-circle" aria-hidden="true"></i></span></p>
              <div className={`collapsible ${isExpanded.personalInfo ? 'expanded' : ''}`}>
                <ul className='footer_for'>
                  <li>My Account</li>
                  <li>Order History</li>
                  <li>Order Tracking</li>
                  <li>Serviceable Pincodes</li>
                  <li>Experience Center</li>
                </ul>
              </div>
            </div>
            <div className='for-mobile'>
              <p className='heading' onClick={() => toggleCollapse('contact')}>Contact &nbsp;<span className='icon-p'><i class="fa fa-plus-circle" aria-hidden="true"></i></span></p>
              <div className={`collapsible ${isExpanded.contact ? 'expanded' : ''}`}>
                <ul className='footer_for'>
                  <li>B-412, 4th Floor, Durian Estate</li>
                  <li>Goregaon-Mulund Link Road</li>
                  <li>Goregaon (East)</li>
                  <li>Mumbai - 400063, India.</li>
                  <li>support@vareprint.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row foot-row'>
          <div className='col-sm-4 first_footer_div'>
            <div className='row footer2-row'>
              <div className='col-sm-6'>
                <p className="connect_wu">Connect with us :</p>
              </div>
              <div className='col-sm-6'>
                <Link to={`/`}><img src={`${baseURL}/images/Facebook.png`} alt='vareprint' className='social_img' /></Link>
                <Link to={`/`}><img src={`${baseURL}/images/Instagram.png`} alt='vareprint' className='social_img' /></Link>
                <Link to={`/`}><img src={`${baseURL}/images/Twitter.png`} alt='vareprint' className='social_img' /></Link>
              </div>
            </div>
          </div>
          <div className='col-sm-4'>
            <p className='connect_wu'>© Vareprint 2021. All rights reserved.</p>
          </div>
          <div className='col-sm-4 payment-icon'>
            <img src={`${baseURL}/images/visa.png`} alt='vareprint' className='social_img2' />
            <img src={`${baseURL}/images/mastercard.png`} alt='vareprint' className='social_img2' />
            <img src={`${baseURL}/images/americanexpress.png`} alt='vareprint' className='social_img2' />
            <img src={`${baseURL}/images/gpaylogo.png`} alt='vareprint' className='social_img2' />
            <img src={`${baseURL}/images/paytm.png`} alt='vareprint' className='social_img2' />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
