import React from 'react';
import Nav from './nav';
import Footer from './footer';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const privacy_policy = () => {

  return (
    <React.Fragment>
      <Nav />
      <div className='container'>
        <ul className='filter_ul'>
          <li className='top_ul'><Link className='top_ul' to={`/`}>Home</Link></li>
          
        </ul>
      </div>
     <div className='container'>
        <div className='row'>
            <div className='col-sm-4'>
            <img src={`${baseURL}/images/images.png`} className='product_image' />
            </div>
            <div className="col-sm-8">
      <div className="row">
        <div className="col-sm-12 toggle-div">
          <p className='collapse-title'
            data-toggle="collapse" 
            data-target="#collapseContent" 
            aria-expanded="false" 
            aria-controls="collapseContent"
          >
         Billing Details
          </p>
          <div className="collapse collapse-profile-div" id="collapseContent">
            <div className="card card-body">
              This is the collapsible content.
            </div>
          </div>
        </div>


        <div className="col-sm-12 toggle-div">
          <p  className='collapse-title'
            data-toggle="collapse" 
            data-target="#collapseContent2" 
            aria-expanded="false" 
            aria-controls="collapseContent"
          >
           Shipping Details
          </p>
          <div className="collapse collapse-profile-div" id="collapseContent2">
            <div className="card card-body">
              This is the collapsible content.
            </div>
          </div>
        </div>


        <div className="col-sm-12 toggle-div">
          <p className='collapse-title'
            data-toggle="collapse" 
            data-target="#collapseContent3" 
            aria-expanded="false" 
            aria-controls="collapseContent"
          >
           Your Order's
          </p>
          <div className="collapse  collapse-profile-div" id="collapseContent3">
            <div className="card card-body">
              This is the collapsible content.
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
     </div>
  
      <Footer />
    </React.Fragment>
  );
};

export default privacy_policy;
