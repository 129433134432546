import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Import Form from react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Link } from 'react-router-dom';

const BestSeller = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
    productimage: null
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: files });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (Array.isArray(formData[key])) {
        Array.from(formData[key]).forEach(file => {
          formDataToSend.append(key, file);
        });
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const response = await fetch('/api/query', {
        method: 'POST',
        body: formDataToSend
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        // Handle success
      } else {
        console.log('Form submission failed');
        // Handle failure
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <React.Fragment>
      <br />
      <div className='container-fluid'>
        <div className='saller-box'>
          <div className='row'>
            <div className='col-sm-12 seller-row'>
              <p className='best-seller'>Best Seller Right Now</p>
            </div>
          </div>
          <div className='row seller-product-row'>
            <div className='col-sm-4'>
              <Link to={`/preview/86`}>
              <img src={`${baseURL}/images/Notebook.webp`} alt="notebook" className='seller_image' />
              </Link>
            </div>
            <div className='col-sm-4'>
            <Link to={`/preview/6`}>
              <img src={`${baseURL}/images/Acrylic Desktop.webp`} alt="acrylic desktop" className='seller_image' />
              </Link>
            </div>
            <div className='col-sm-4'>
            <Link to={`/preview/89`}>
              <img src={`${baseURL}/images/T-srhit.webp`} alt="t-shirt" className='seller_image' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='container'>
        <div className='row icon-row'>
          <div className='col-sm-6 icon-div'>
            <img src={`${baseURL}/images/ask_1.svg`} alt="icon_ad" className='icon_ad1' onClick={handleShow} />
            <p className='title-icon'>Bulk Enquiry</p>
          </div>
          <div className='col-sm-6 icon-div'>
            <Link to={`https://wa.me/message/O63DVQF7INFCO1`}>
              <img src={`${baseURL}/images/whatsapp.svg`} alt="whatsapp" className='icon_ad' />
            </Link>
            <p className='title-icon'>whatsapp Assistance</p>
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Bulk Enquiry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>*Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>*Email:</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formContact">
                  <Form.Label>*Mobile no.:</Form.Label>
                  <Form.Control
                    type="number"
                    name="contact"
                    placeholder="Mobile no."
                    value={formData.contact}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formMessage">
                  <Form.Label>*Message:</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="message"
                    placeholder="Your text here!"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formFile">
                  <Form.Label>Upload an image:</Form.Label>
                  <Form.Control
                    type="file"
                    name="productimage[]"
                    multiple
                    onChange={handleChange}
                  />
                </Form.Group>
                <br />
                <div className='row button-row-modal'>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type="submit" style={{ background: '#f8d500' }}>
                  Submit
                </Button>
                </div>
                
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
};

export default BestSeller;
