import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Nav from './nav';
import Footer from './footer';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useUser } from './user'; // Adjust the import path as needed
const baseURL = process.env.REACT_APP_API_BASE_URL;

function Checkout() {
    const { userId, login, sessionUser, cookieUser, guestUser } = useUser();
    const navigate = useNavigate();
    const { guest } = useParams();

    const [currentUserId, setCurrentUserId] = useState(userId);
    const [user, setUser] = useState({
        
        firstName: '',
        lastName: '',
        email: '',
        contact: '',
        bill_address: '',
        postcode: '',
        city: '',
        state: '',
        country: '',
        b_name: '',
        b_email: '',
        b_contact: '',
        b_address: '',
        b_postcode: '',
        b_city: '',
        b_state: '',
        b_country: ''
    });
    const [formData, setFormData] = useState({
        amount: '',
        email: '',
        phone: '',
        fullname: '',
        city: '',
        state: '',
        country: '',
        zip_code: '',
        product_description: 'product2',
        order_id: '',
        userid: userId,
        username: ''
    });
    const [cart, setCart] = useState('');
    const [code, setCode] = useState('');
    const [isSameAsShipping, setIsSameAsShipping] = useState(false);
    const [isAddNewAddress, setIsAddNewAddress] = useState(false);

    useEffect(() => {
        if (guest) {
          
            setCurrentUserId(guest);
            // document.cookie = `guestUser=${guestId}; path=/;`;
        } else {
            setCurrentUserId(userId);
        }
    }, [guest, userId]);

    useEffect(() => {
        const updateUser = async () => {
            if (cookieUser) {
                try {
                    await axios.post(`${baseURL}/user-update`, { cookieUser, currentUserId });
                } catch (error) {
                    console.error('Error updating user:', error);
                }
            }
        };

        updateUser();
    }, [cookieUser, currentUserId]);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${baseURL}/fetch-checkout/${currentUserId}`);
                console.log('Response from fetch-checkout:', response.data);
    
                if (response.data.success) {
                    const userData = response.data.user || {};
                    const cartData = response.data.cartdetails || {};
    
                    setUser({
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        email: userData.email,
                        contact: userData.contact,
                        bill_address: userData.bill_address,
                        postcode: userData.postcode,
                        city: userData.city,
                        state: userData.state,
                        country: userData.country,
                        b_name: userData.b_name,
                        b_email: userData.b_email,
                        b_contact: userData.b_contact,
                        b_address: userData.b_address,
                        b_postcode: userData.postcode,
                        b_city: userData.b_city,
                        b_state: userData.b_state,
                        b_country: userData.b_country
                    });
    
                    setCart(cartData || {});
    
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        email: userData.email || '',
                        phone: userData.contact || '',
                        fullname: `${userData.firstName || ''} ${userData.lastName || ''}`,
                        city: userData.city || '',
                        state: userData.state || '',
                        country: userData.country || '',
                        zip_code: userData.postcode || '',
                        userid: userData.uid ? userData.uid.toString() : '',
                        username: userData.firstName || '',
                        amount: response.data.total ? response.data.total.toString() : '',
                        order_id: cartData.order_id ? cartData.order_id.toString() : '',
                        gst_amount: cartData.gst_amount || 0,
                    }));
                } else {
                    console.error('Failed to fetch user profile:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
    
        if (currentUserId && !guest) {
            fetchUserProfile();
        }
    }, [currentUserId]);
    
  
    
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${baseURL}/fetch-checkout-guest/${guest}`);
                console.log('Response from fetch-checkout-guest:', response.data);
    
                if (response.data.success) {
                    const userData = response.data.user || {};
                    const cartData = response.data.cartdetails || {};
    
                    setUser({
                        firstName: userData.firstName || '',
                        lastName: userData.lastName || '',
                        email: userData.email || '',
                        contact: userData.contact || '',
                        bill_address: userData.bill_address || '',
                        postcode: userData.postcode || '',
                        city: userData.city || '',
                        state: userData.state || '',
                        country: userData.country || '',
                        b_name: '',
                        b_email: '',
                        b_contact: '',
                        b_address: '',
                        b_postcode: '',
                        b_city: '',
                        b_state: '',
                        b_country: ''
                    });
    
                    setCart(cartData || {});
    
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        email: userData.email || '',
                        phone: userData.contact || '',
                        fullname: `${userData.firstName || ''} ${userData.lastName || ''}`,
                        city: userData.city || '',
                        state: userData.state || '',
                        country: userData.country || '',
                        zip_code: userData.postcode || '',
                        userid: userData.uid ? userData.uid.toString() : '',
                        username: userData.firstName || '',
                        amount: response.data.total ? response.data.total.toString() : '',
                        order_id: cartData.order_id ? cartData.order_id.toString() : '',
                        gst_amount: cartData.gst_amount || 0,
                    }));
                } else {
                    console.error('Failed to fetch user profile:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
    
        if (guest) {
            fetchUserProfile();
        }
    }, [currentUserId]);
    

    const handlePostcode = async (postcode) => {
        try {
            const response = await axios.get(`${baseURL}/postcode/${postcode}`);
    
            // Check if response data has the 'code' key and it's an array with at least one item
            if (response.data && Array.isArray(response.data.code) && response.data.code.length > 0) {
                const data = response.data.code[0];
    
                // Check if data contains the expected properties
                if (data && data.postcode && data.city && data.state) {
                    setUser(prevUser => ({
                        ...prevUser,
                        postcode: data.postcode,
                        city: data.city,
                        state: data.state,
                        // Assuming country is not returned, you might want to handle it differently
                        country: data.country  // Keep existing value if not provided
                    }));
                } else {
                    console.error('Unexpected data structure:', data);
                }
            } else {
                console.error('No data found or unexpected response format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching postcode data:', error);
        }
    };
    
    const handlePostcode2 = async (postcode) => {
        try {
            const response = await axios.get(`${baseURL}/postcode/${postcode}`);
    
            // Check if response data has the 'code' key and it's an array with at least one item
            if (response.data && Array.isArray(response.data.code) && response.data.code.length > 0) {
                const data = response.data.code[0];
    
                // Check if data contains the expected properties
                if (data && data.postcode && data.city && data.state) {
                    setUser(prevUser => ({
                        ...prevUser,
                        b_postcode: data.postcode,
                        b_city: data.city,
                        b_state: data.state,
                        // Assuming country is not returned, you might want to handle it differently
                        b_country: data.country  // Keep existing value if not provided
                    }));
                } else {
                    console.error('Unexpected data structure:', data);
                }
            } else {
                console.error('No data found or unexpected response format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching postcode data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            let updateUserResponse;
            if (guest) {
                // Insert data into the guest table
                updateUserResponse = await axios.post(`${baseURL}/update-guest`, { ...user, currentUserId });
            } else {
                // Update data in the user table
                updateUserResponse = await axios.post(`${baseURL}/update-user`, { userId: currentUserId, ...user });
            }
    
            if (updateUserResponse.status === 200) {
                const response = updateUserResponse.data;
    
                if (response.success) {
                    const userData = response.user || {};
                    const cartData = response.cartdetails[0] || {}; // Assuming cartdetails is an array
    
                    setUser({
                        firstName: userData.firstName || '',
                        lastName: userData.lastName || '',
                        email: userData.email || '',
                        contact: userData.contact || '',
                        bill_address: userData.bill_address || '',
                        postcode: userData.postcode || '',
                        city: userData.city || '',
                        state: userData.state || '',
                        country: userData.country || '',
                        b_name: userData.b_name || '',
                        b_email: userData.b_email || '',
                        b_contact: userData.b_contact || '',
                        b_address: userData.b_address || '',
                        b_postcode: userData.b_postcode || '',
                        b_city: userData.b_city || '',
                        b_state: userData.b_state || '',
                        b_country: userData.b_country || ''
                    });
    
                    setCart(cartData || {});
    
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        email: userData.email || '',
                        phone: userData.contact || '',
                        fullname: `${userData.firstName || ''} ${userData.lastName || ''}`,
                        city: userData.city || '',
                        state: userData.state || '',
                        country: userData.country || '',
                        zip_code: userData.postcode || '',
                        userid: userData.uid ? userData.uid.toString() : '',
                        username: userData.firstName || '',
                        amount: response.total ? response.total.toString() : '',
                        order_id: cartData.order_id ? cartData.order_id.toString() : '',
                        gst_amount: cartData.gst_amount || 0,
                    }));
    
                    // Process payment
                    const paymentResponse = await fetch(`${baseURL}/process_payment`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            user: { ...user, currentUserId },  // Send user and payment details
                            payment: formData
                        }),
                    });
    
                    const paymentData = await paymentResponse.json();
    
                    if (paymentData.status === 'success') {
                        // Create and submit the payment form
                        const paymentForm = document.createElement('form');
                        paymentForm.action = 'https://biz.aggrepaypayments.com/v2/paymentrequest';
                        paymentForm.method = 'POST';
    
                        for (const key in paymentData.form) {
                            const input = document.createElement('input');
                            input.type = 'hidden';
                            input.name = key;
                            input.value = paymentData.form[key];
                            paymentForm.appendChild(input);
                        }
    
                        document.body.appendChild(paymentForm);
                        paymentForm.submit();
                    } else {
                        console.error('Payment processing failed:', paymentData.message);
                    }
                } else {
                    console.error('Failed to save user data:', updateUserResponse.statusText);
                    return; // Exit if saving user data fails
                }
            } else {
                console.error('Failed to update user data:', updateUserResponse.statusText);
            }
        } catch (error) {
            console.error('Error in submission:', error);
        }
    };
    
    const handleCheckboxChange = (type) => {
        if (type === 'sameAsShipping') {
            setIsSameAsShipping(!isSameAsShipping);
            setIsAddNewAddress(false);
            if (!isSameAsShipping) {
                setUser(prevUser => ({
                    ...prevUser,
                    b_name: prevUser.firstName,
                    b_email: prevUser.email,
                    b_contact: prevUser.contact,
                    b_address: prevUser.bill_address,
                    b_postcode: prevUser.postcode,
                    b_city: prevUser.city,
                    b_state: prevUser.state,
                    b_country: prevUser.country
                }));
            } else {
                setUser(prevUser => ({
                    ...prevUser,
                    b_name: '',
                    b_email: '',
                    b_contact: '',
                    b_address: '',
                    b_postcode: '',
                    b_city: '',
                    b_state: '',
                    b_country: ''
                }));
            }
        } else {
            setIsAddNewAddress(!isAddNewAddress);
            setIsSameAsShipping(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCode(e.target.value);
        setUser(prevUser => ({
            ...prevUser,
            [name]: value
        }));
        if (name === 'postcode' && value.length === 6) {
            handlePostcode(value);
        }
        if (name === 'b_postcode' && value.length === 6) {
            handlePostcode2(value);
        }
    };

  
    return (
        <React.Fragment>
            <Nav />
            <div className='container'>
        <ul className='filter_ul'>
          <Link to={`/`}>
            <li>Home</li>
          </Link>
          {/* <li>&nbsp;/&nbsp;</li>
          <li>Products</li> */}
        </ul>
      </div>
            <div className='container'>
                {currentUserId ? (
                    <div className='checkout-div'>
                        <div className='row checkout_row'>
                            <div className='col-sm-8 details_div'>
                                <h4>Shipping Address</h4>
                                <hr />
                                <div className='form-group row'>
                                    <div className='col-sm-2'>Name</div>
                                    <div className='col-sm-4'><input type='text' className='form-control' name='firstName' value={user.firstName} onChange={ handleInputChange} /></div>
                                    <div className='col-sm-2'>Contact</div>
                                    <div className='col-sm-4'><input type='number' className='form-control' name='contact' value={user.contact} onChange={ handleInputChange} /></div>
                                </div>
                                <div className='form-group row'>
                                    <div className='col-sm-2'>Address</div>
                                    <div className='col-sm-10'><input type='text' className='form-control' name='bill_address' value={user.bill_address} onChange={ handleInputChange} /></div>
                                </div>
                                <div className='form-group row'>
                                    <div className='col-sm-2'>Email</div>
                                    <div className='col-sm-10'><input type='text' className='form-control' name='email' value={user.email} onChange={ handleInputChange} /></div>
                                </div>
                                <div className='form-group row'>
                                    <div className='col-sm-2'>Post Code</div>
                                    <div className='col-sm-4'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='postcode'
                                            value={user.postcode}
                                            
                                            onChange={(e) => {
                                                setCode(e.target.value);
                                                handleInputChange(e);
                                              }}
                                        />
                                    </div>
                                    <div className='col-sm-2'>City</div>
                                    <div className='col-sm-4'><input type='text' className='form-control' name='city' value={user.city} readOnly /></div>
                                </div>
                                <div className='form-group row'>
                                    <div className='col-sm-2'>State</div>
                                    <div className='col-sm-4'><input type='text' className='form-control' name='state' value={user.state} readOnly /></div>
                                    <div className='col-sm-2'>Country</div>
                                    <div className='col-sm-4'><input type='text' className='form-control' name='country' value={user.country} readOnly /></div>
                                </div>
                                <hr />
                                <h4>Billing Address</h4>
                                <hr />
                                <input type='checkbox' checked={isSameAsShipping} onChange={() => handleCheckboxChange('sameAsShipping')} className='shipping-checkbox'/>Same as shipping <br />
                                <input type="checkbox" checked={isAddNewAddress} onChange={() => handleCheckboxChange('addNewAddress')} />Add New Address

                                {isAddNewAddress && (
                                    <div className='collapse show'>
                                        <hr />
                                        <div className='form-group row'>
                                            <div className='col-sm-2'>Name</div>
                                            <div className='col-sm-4'><input type='text' className='form-control' name='b_name'  onChange={handleInputChange} /></div>
                                            <div className='col-sm-2'>Contact</div>
                                            <div className='col-sm-4'><input type='number' className='form-control' name='b_contact' value='' onChange={handleInputChange} /></div>
                                        </div>
                                        <div className='form-group row'>
                                            <div className='col-sm-2'>Address</div>
                                            <div className='col-sm-10'><input type='text' className='form-control' name='b_address'  onChange={handleInputChange} /></div>
                                        </div>
                                        <div className='form-group row'>
                                            <div className='col-sm-2'>Email</div>
                                            <div className='col-sm-10'><input type='text' className='form-control' name='b_email' onChange={handleInputChange} /></div>
                                        </div>
                                        <div className='form-group row'>
                                            <div className='col-sm-2'>Post Code</div>
                                            <div className='col-sm-4'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='b_postcode'
                                                    value={user.b_postcode}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className='col-sm-2'>City</div>
                                            <div className='col-sm-4'><input type='text' className='form-control' name='b_city'   value={user.b_city}  readOnly /></div>
                                        </div>
                                        <div className='form-group row'>
                                            <div className='col-sm-2'>State</div>
                                            <div className='col-sm-4'><input type='text' className='form-control' name='b_state'   value={user.b_state} readOnly /></div>
                                            <div className='col-sm-2'>Country</div>
                                            <div className='col-sm-4'><input type='text' className='form-control' name='b_country' value={user.b_country} readOnly /></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-sm-4 checkout_button_div'>
                                <form onSubmit={handleSubmit}>
                                    <button type="submit" className='checkout-button'>PROCEED</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Please log in.</p>
                )}
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default Checkout;
