import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Nav from './nav';
import Footer from './footer';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from './user'; // Adjust the import path as needed

const LoginRegister = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const {userId, login } = useUser();

  const navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [contact, setContact] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [message1, setMessage1] = useState('');
  const [registerStep, setRegisterStep] = useState(1);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        // Call login function and check the response
        const response = await login(identifier, password);
        
        // Assuming login function returns an object with a `success` property
        if (response.success) {
            navigate('/checkout'); // Navigate to /checkout if login is successful
        } else {
            setMessage(response.message || 'Login failed');
        }
    } catch (error) {
        // Handle any errors that occurred during login
        setMessage(error.message || 'Login failed');
    }
};
const generateGuestId = () => {
  const randomNumber = Math.floor(100000 + Math.random() * 900000); // 6-digit number
  return `G-${randomNumber}`;
};
const GuestUser = async () =>{

const guest = generateGuestId();
 axios.post(`${baseURL}/insert-guest`, {guest});
navigate(`/checkout/${guest}`);
}
  const handleGenerateOtp = async () => {
    try {
      const response = await axios.post(`${baseURL}/generate-otp`, { email, contact });
      setMessage1(response.data.message);
      if (response.data.success) {
        setRegisterStep(2);
      }
    } catch (error) {
      console.error('Error generating OTP:', error);
      setMessage1('Error generating OTP');
    }
  };

  const handleRegister = async () => {
    try {
      const response = await axios.post(`${baseURL}/verify-otp`, { firstName, lastName, email, contact, otp });
      setMessage1(response.data.message);
      if (response.data.success) {
        setRegisterStep(3);
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setMessage1('Error during registration');
    }
  };

  const handlePassword = async () => {
    try {
      const response = await axios.post(`${baseURL}/save-user`, { firstName, lastName, password, email, contact });
      setMessage1(response.data.message);
      if (response.data.success) {
        navigate('/login-page'); // Redirect to login page after successful registration
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setMessage1('Error during registration');
    }
  };

  return (
    <React.Fragment>
      <Nav />
      <div className='container'>
        <div className='row login-container-row'>
          <div className='col-sm-6 sign_in_div'>
            <div>
              <p className='sign_in'>SIGN IN</p>
              <p className='signintitle'>Sign in with your email and password</p>
              <hr />
              {message && <p>{message}</p>}
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <label>Email or Phone Number:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Password:</label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              
              
            
              <p className='instruction-login'><input type='checkbox' /> I accept your Terms & Conditions and Privacy Policy</p>
              <p className='instruction-login'>Forgot Password?</p>
              <hr />
              <div className='form-group row login-button-div'>
                <div className='col-sm-6'>
                  
                  <button className='continue_shopping' onClick={handleLogin}>SIGN IN</button>
                </div>
                <div className='col-sm-6'>
                  <button className='continue_shopping'>CONTINUE WITH GOOGLE</button>
                </div>
              </div>
              </form>
              <h4>Continue as a guest</h4>
              <p>You can create an account after checkout for a faster experience next time.</p>
            
                <button className='continue_shopping' onClick={GuestUser}>CHECKOUT AS A GUEST</button>
              
            
            </div>
          </div>

          <div className='col-sm-6 sign_up_div'>
            {registerStep === 1 && (
              <div>
                <p className='sign_in'>CREATE YOUR ACCOUNT</p>
                <hr />
                <div className='form-group row'>
                  <label>First Name:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter First Name'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <label>Last Name:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Last Name'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <label>Email Address:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Email Address'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <label>Contact:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Contact Number'
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <div className='col-sm-12'>
                    <button className='continue_shopping' onClick={handleGenerateOtp}>Generate OTP</button>
                  </div>
                </div>
                <p>{message1}</p>
              </div>
            )}

            {registerStep === 2 && (
              <div>
                <p>VERIFY OTP</p>
                <hr />
                <div className='form-group row'>
                  <label>OTP:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter OTP'
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <div className='col-sm-12'>
                    <button className='continue_shopping' onClick={handleRegister}>Verify</button>
                  </div>
                </div>
                <p>{message1}</p>
              </div>
            )}

            {registerStep === 3 && (
              <div>
                <p>Enter Password</p>
                <hr />
                <div className='form-group row'>
                  <label>Password:</label>
                  <input
                    type='password'
                    className='form-control'
                    placeholder='Enter Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <div className='col-sm-12'>
                    <button className='continue_shopping' onClick={handlePassword}>REGISTER</button>
                  </div>
                </div>
                <p>{message1}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default LoginRegister;
