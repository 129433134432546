import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [userId, setUserId] = useState(null);
    const [cookieUser, setcookiUser] = useState(null);
    const [sessionUser, setsessioUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const generateRandomString = (length) => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };

    useEffect(() => {
        const initializeUser = async () => {
            let userIdFromSession;

            try {
                // Check if session profile exists
                const response = await axios.get(`${baseURL}/profile`, { withCredentials: true });
                const userProfile = response.data.user;

                if (userProfile && userProfile.uid) {
                    // If session profile exists, use userId from session
                    userIdFromSession = userProfile.uid;
                }
            } catch (error) {
                console.error('Error checking session profile:', error);
            }

            let userIdToSet;

            if (userIdFromSession) {
                // If session user exists, set userId from session
                userIdToSet = userIdFromSession;
                setsessioUser(userIdToSet);
            } else {
                // If no session user, create a new cookie-based userId
                let userIdFromCookie = Cookies.get('userId');
                if (!userIdFromCookie) {
                    userIdFromCookie = generateRandomString(10);
                    Cookies.set('userId', userIdFromCookie, { expires: 1 }); // 1 day
                }
                userIdToSet = userIdFromCookie;
                setcookiUser(userIdToSet);
            }

            setUserId(userIdToSet);
            setLoading(false);
        };

        initializeUser();
    }, []);

    const fetchProfile = async () => {
        try {
            const response = await axios.get(`${baseURL}/profile`, { withCredentials: true });
            const userProfile = response.data.user;
            if (userProfile && userProfile.length > 0) {
                setUserId(userProfile[0].uid); // Assuming userProfile contains an 'uid' field
                return userProfile;
            } else {
                throw new Error('User profile not found or empty');
            }
        } catch (error) {
            throw new Error(error.response?.data?.message || 'Failed to fetch profile');
        }
    };

    const login = async (identifier, password) => {
        try {
            setLoading(true);
            const response = await axios.post(`${baseURL}/login`, { identifier, password }, { withCredentials: true });
            setUserId(response.data.userId);

            // const userProfile = await fetchProfile();
            // console.log(userProfile)
            setLoading(false);
            return { success: true };
        } catch (error) {
            setLoading(false);
            throw new Error(error.response?.data?.message || 'Login failed');
        }
    };

    const logout = async () => {
        try {
            setLoading(true);
            await axios.post(`${baseURL}/logout`, {}, { withCredentials: true });
            Cookies.remove('userId');
            const newGuestUserId = generateRandomString(10);
            Cookies.set('userId', newGuestUserId, { expires: 1 });
            setUserId(newGuestUserId);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw new Error(error.response?.data?.message || 'Logout failed');
        }
    };

    return (
        <UserContext.Provider value={{ userId, loading, login, logout, cookieUser, sessionUser }}>
            {children}
        </UserContext.Provider>
    );
};

const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

export { UserProvider, useUser };
