import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Sidebar from './sidebar';
import Navbar from './navbar';
import Footer from './footer';




const baseURL = process.env.REACT_APP_API_BASE_URL;

const Design = () => {
  const [designs, setDesigns] = useState([]);

  useEffect(() => {
    // Fetch all designs
    axios.get(`${baseURL}/fetch-design`)
      .then(response => {
        setDesigns(response.data);
        initializeDataTable();
      })
      .catch(error => {
        console.error('Error fetching designs:', error);
      });
  }, []);

  const initializeDataTable = () => {
    setTimeout(() => {
      $('#designTable').DataTable();
    }, 100); // Timeout to ensure data is rendered before initializing DataTable
  };

  const handleDelete = (designId) => {
    // Delete design by ID
    axios.delete(`${baseURL}/delete-design/${designId}`)
      .then(response => {
        // Update the state to remove the deleted design
        setDesigns(designs.filter(design => design.id !== designId));
        // Re-initialize DataTable after deletion
        $('#designTable').DataTable().destroy();
        initializeDataTable();
      })
      .catch(error => {
        console.error('Error deleting design:', error);
      });
  };

  return (
    <div className="layout">
    <Sidebar />
    <div className="main-content">
      <Navbar />
      <div className="content">
    <div className='container'>
      <h2>Designs</h2>
      <table id="" className="table-border">
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {designs.map(design => (
            <tr key={design.id}>
              <td>
                <img
                  src={`${baseURL}/images/design/${design.images}`} // Ensure your API provides the correct image URL
                  alt={design.dname}
                  style={{ height: '50px', objectFit: 'cover' }}
                />
              </td>
              <td>{design.dname}</td>
              <td>{design.description}</td>
              <td>
                <div className='d-flex justify-content-between'>
                  <Link to={`/edit-design/${design.did}`} className='btn btn-primary btn-sm'>
                    Edit
                  </Link>
                  <button
                    onClick={() => handleDelete(design.did)}
                    className='btn btn-danger btn-sm'
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
    <Footer />
    </div>
  </div>
  );
};

export default Design;
