import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom to access query parameters

const PaymentResponsePage = () => {
    const [response, setResponse] = useState({});
    const location = useLocation();

    useEffect(() => {
        const fetchResponse = async () => {
            try {
                const searchParams = new URLSearchParams(location.search);
                const postData = {};
                for (const [key, value] of searchParams) {
                    postData[key] = value;
                }

                // Calculate hash to verify response integrity
                const calculatedHash = hashCalculate(postData.salt, postData);

                // Verify if calculated hash matches received hash
                const validHash = (postData.hash === calculatedHash) ? 'Yes' : 'No';
                postData.calculated_hash = calculatedHash;
                postData.valid_hash = validHash;

                setResponse(postData);
            } catch (error) {
                console.error('Error processing payment response:', error);
            }
        };

        fetchResponse();
    }, [location.search]);

    const hashCalculate = (salt, input) => {
        // Remove hash key if it is present
        delete input['hash'];
        
        // Sort the array before hashing
        const sortedKeys = Object.keys(input).sort();
        let hashData = salt;

        sortedKeys.forEach(key => {
            if (input[key]) {
                hashData += '|' + input[key];
            }
        });

        const hash = crypto.createHash('sha512').update(hashData).digest('hex').toUpperCase();
        return hash;
    };

    return (
        <div>
            <h1>AggrePay Payment API Integration Test Kit</h1>
            <h3>NOTE: It is very important to calculate the hash using the returned value and compare it against the hash that was sent with the payment request to ensure the response is legitimate.</h3>
            <h2>Response from AggrePay</h2>
            <table>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(response).map(([key, value]) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>{value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PaymentResponsePage;
