import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Link } from 'react-router-dom';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Design = () => {
  const { designId } = useParams();
  const [designs1, setDesigns1] = useState([]);
  const [designs2, setDesigns2] = useState([]);
  const [designs3, setDesigns3] = useState([]);
  const [product, setProduct] = useState('');

  // New state for form inputs
  const [formData, setFormData] = useState({
    whiteImage: '',
    blackImage: '',
    whiteDesignId: '',
    blackDesignId: '',
    whitePrice: '',
    blackPrice: ''
  });

  // Image upload state
  const [images, setImages] = useState({
    whiteImageFile: null,
    blackImageFile: null
  });

  // State for notifications
  const [notification, setNotification] = useState({
    message: '',
    type: '' // 'success' or 'error'
  });

  useEffect(() => {
    // Your existing useEffect code here
  }, [designId]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle file changes
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setImages({
      ...images,
      [name]: files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
  
    // Append form data
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });
  
    // Append files
    if (images.whiteImageFile) {
      formDataToSend.append('whiteImage', images.whiteImageFile);
    }
    if (images.blackImageFile) {
      formDataToSend.append('blackImage', images.blackImageFile);
    }
  
    // Append designId
    formDataToSend.append('designId', designId);
  
    try {
      await axios.post(`${baseURL}/upload-design`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setNotification({
        message: 'Design updated successfully!',
        type: 'success'
      });
      // Clear form inputs
      setFormData({
        whiteImage: '',
        blackImage: '',
        whiteDesignId: '',
        blackDesignId: '',
        whitePrice: '',
        blackPrice: ''
      });
      setImages({
        whiteImageFile: null,
        blackImageFile: null
      });
      // Remove notification after 2 seconds
      setTimeout(() => {
        setNotification({
          message: '',
          type: ''
        });
      }, 2000);
    } catch (error) {
      setNotification({
        message: 'Error updating design. Please try again.',
        type: 'error'
      });
      console.error('Error updating design:', error);
    }
  };

  return (
    <React.Fragment>
      
      <div className='container'>
        <ul className='filter_ul'>
          <Link to={`/`}>
            <li>Home </li>
          </Link>
          <li>&nbsp;/&nbsp;</li>
          <li>{product}</li>
        </ul>
      </div>
    
      <div className='container'>
        <h2>Update Design Details</h2>
        {notification.message && (
          <div className={`notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor='whiteImage'>White Image</label>
            <input
              type='file'
              id='whiteImage'
              name='whiteImageFile'
              onChange={handleFileChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='blackImage'>Black Image</label>
            <input
              type='file'
              id='blackImage'
              name='blackImageFile'
              onChange={handleFileChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='whiteDesignId'>White Design ID</label>
            <input
              type='text'
              id='whiteDesignId'
              name='whiteDesignId'
              value={formData.whiteDesignId}
              onChange={handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='blackDesignId'>Black Design ID</label>
            <input
              type='text'
              id='blackDesignId'
              name='blackDesignId'
              value={formData.blackDesignId}
              onChange={handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='whitePrice'>White Price</label>
            <input
              type='number'
              id='whitePrice'
              name='whitePrice'
              value={formData.whitePrice}
              onChange={handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='blackPrice'>Black Price</label>
            <input
              type='number'
              id='blackPrice'
              name='blackPrice'
              value={formData.blackPrice}
              onChange={handleChange}
            />
          </div>
          <button type='submit' className='btn btn-primary'>Update Design</button>
        </form>
      </div>
      <br />
    
    </React.Fragment>
  );
};

export default Design;
