import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './style/forcandara.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './component/user'; // Adjust the import path as needed

ReactDOM.render(
  <React.Fragment>
  <BrowserRouter>
  <UserProvider>
  <App />
  </UserProvider>
    
  </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root')
);

reportWebVitals();
