import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [isDesignMenuOpen, setIsDesignMenuOpen] = useState(false);

  const toggleDesignMenu = () => {
    setIsDesignMenuOpen(!isDesignMenuOpen);
  };

  return (
    <div className="sidebar">
      <ul>
      <li><Link to="/admin-dashboard">Orders</Link></li>
        <li>
          <div onClick={toggleDesignMenu} style={{ cursor: 'pointer' }}>
            Design {isDesignMenuOpen ? '▼' : '▶'}
          </div>
          {isDesignMenuOpen && (
            <ul>
              <li><Link to="/add-design">Add Design</Link></li>
              <li><Link to="/design-page">Edit Design</Link></li>
            </ul>
          )}
        </li>
        <li><Link to="/profile">Profile</Link></li>
        <li><Link to="/settings">Settings</Link></li>
      </ul>
    </div>
  );
};

export default Sidebar;
