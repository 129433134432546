// Carousel.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import CustomArrow from './arrow';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './slider.css';  // Ensure you have the CSS imports here
const baseURL = process.env.REACT_APP_API_BASE_URL;

const Carousel = () => {
  const [sliderItems, setSliderItems] = useState([]);

  useEffect(() => {
    // Fetch slider images from the server
    axios.get(`${baseURL}/slider-images`)
   
      .then(response => {
        const siders = response.data.slider;
        setSliderItems(siders); // Assuming response.data is an array of slider items
      })
      .catch(error => {
        console.error('Error fetching slider images:', error);
      });
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='container'>
      <div className="carousel-container">
        <h3 className='explore_title'>Explore Personalised Gifts</h3>
        <Slider {...settings}>
          {sliderItems.map(slider => (
            <div key={slider.product} className='slick_div'>
              <Link to={`/design/${slider.product}`}>
              <div className='circle'>
                <img
                  src={`${baseURL}/images/design/${slider.images}`}
                  alt={`Slide ${slider.product}`}
                  className="circle-img"
                />
                
                </div>
                <p className='slider-pname'>{slider.product_name}</p>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
      {/* <br /> */}
      <hr className="split" />
    </div>
  );
};

export default Carousel;
