import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import './Main.css'; // Import your CSS file for styling

const Main = () => {
    const navigate =  useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleNavigation = (path) => {
        // Clear the route and navigate to the new path
        navigate(`/design/${path}`);
      };

    return (
        <React.Fragment>
            <div className='row topbar' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className='container'>
                <p className='personalise_title'>PERSONALISE</p>
                </div>
                <div className={`collapsible-container ${isHovered ? 'collapsed' : ''}`}>
                    <div className="content">
                       <div className='container'>
                        <div className='row top_nav_row hover-row-top'>
                        <div className='col-sm-4 ul_div'>
                            <ul className='ul_sub_div'>
                          <li className='top_ul'  onClick={() => handleNavigation(1)}>Acrylic Desktops</li>
                            <li className='top_ul' onClick={() => handleNavigation(2)}>Canvas Prints</li>
                           <li className='top_ul' onClick={() => handleNavigation(3)}>Wooden Desktops</li>
                            </ul>
                        </div>
                        <div className='col-sm-4 ul_div'>
                            <ul className='ul_sub_div'>
                           <li className='top_ul' onClick={() => handleNavigation(5)}>Notebooks</li>
                           <li className='top_ul' onClick={() => handleNavigation(7)}>Coasters</li>
                            <li className='top_ul' onClick={() => handleNavigation(8)}>Tablemats</li>
                            </ul>
                        </div>
                        <div className='col-sm-4 ul_div'>
                            <ul className='ul_sub_div'>
                   <li className='top_ul' onClick={() => handleNavigation(9)}>Fridge Whiteboards</li>
                           <li className='top_ul' onClick={() => handleNavigation(10)}>T-Shirts</li>
                            <li className='top_ul' onClick={() => handleNavigation(24)}>Framed Prints</li>
                            </ul>
                        </div>
                        </div>
                       </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Main;
