// main.js
import React, { useState  } from 'react';
import { useNavigate } from 'react-router-dom'
import './app.css';

function Main() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const handleLogin = () => {
    // Perform login logic here, such as validation and authentication
    if (username === '1' && password === '2') {
      navigate('/admin-dashboard');
    } else {
      alert('Invalid username or password. Please try again.');
    }
  };

  return (
    <React.Fragment>
      <div className='container'>
        <div className='row admin_row'>
          <div className='col-sm-8'>
          <div className='card admin_card'>
          <h3>Admin Login</h3>
          <hr />
        
            <label>User Name:</label>
            <input
              type='text'
              className='form-control'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
        
         
            <label>Password:</label>
            <input
              type='password' // Use type='password' for password fields to hide input characters
              className='form-control'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
         
          
            <button className='btn btn-primary' onClick={handleLogin}>
              Login
            </button>
          
        </div>
          </div>
        </div>
        
      </div>
    </React.Fragment>
  );
}

export default Main;
