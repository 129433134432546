import React from 'react';
import './App.css';
import './mobile.css';
import './desktop.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './component/main';
import Preview from './component/preview';
// import Banner from './component/banner';
import Design from './component/design';
import Design_for_notbook from './component/design_for_notbook'
import Editor from './component/editor';
import Cart from './component/cart';
import Login_User from './component/login';
import ScrollToTop from './component/scrolltotop';
import Payment from './component/payement';
import HandleResponse from './component/handleresponse';
import Slider2 from './component/slick_slider';
import Test from './component/test';

import Contact_us from './component/contact_us';
import About from './component/about';
import Term_conditions from './component/terms_condition';
import Privacy_policy from './component/privacy_policy';
import ProfilePage from './component/profile';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import User_details from './component/user_details'
import Checkout from './component/checkout'



import Main2 from './feadmin/main'; 

// import Layout from './layout';
import DesignPage from  '././feadmin/designpage';
import Layout from '././feadmin/layout';
import Dashboard from '././feadmin/dashboard';
import Profile from '././feadmin/profile';
import Settings from '././feadmin/settings';
import EditDesign from '././feadmin/editdesign';
function App() {
  return (
   
    <div className="App">
    <ScrollToTop />
    <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/slick" element={<Slider2 />} />
          <Route path="design/:productId" element={<Design />} />
          <Route path="notebook/:productId" element={<Design_for_notbook />} />
          <Route path="preview/:designId" element={<Preview />} />
          <Route path="/editor/:designId/:did/:pid/:oid" element={<Editor />} />
          <Route path="cart-page/" element={<Cart />} />
          <Route path="test/" element={<Test />} />
          <Route path="/login-page" element={<Login_User />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/handle_response" element={<HandleResponse />} />
          <Route path="/user-dashboard" element={<User_details />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/:guest" element={<Checkout />} />
          <Route path="/profile-page" element={<ProfilePage />} />

          <Route path="/privacy-policy" element={<Privacy_policy />} />
          <Route path="/terms-condition" element={<Term_conditions />} />
          <Route path="/contact-us" element={<Contact_us />} />
          <Route path="/about-us" element={<About />} />

          {/* Admin Routes */}
          <Route path="/admin-login" element={<Main2 />} />
          <Route path="/admin-dashboard" element={<Layout />} />
          <Route path="/add-design" element={<Dashboard />} />
          <Route path="/design-page" element={<DesignPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/edit-design/:designId" element={<EditDesign />} />
        </Routes>
      </div>
    
  );
}

export default App;
