// Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
<>

<p>Order page here</p>
</>
  );
};

export default Layout;
